<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-5"
      />
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-4"
          >
            {{ $t('FORGOTTEN_PASSWORD') }} ?
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="checkLogin()"
            >
              <!-- email -->
              <b-form-group
                label="Email :"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                v-if="loading"
                variant="primary"
                type="button"
                block
                disabled
              >
                <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
              </b-button>
              <b-button
                v-else
                variant="primary"
                type="submit"
                block
              >
                {{ $t('RENEW_THE_PASSWORD') }}
              </b-button>
            </b-form>
          </validation-observer>
          <div>
            <b-link
              @click="goLogin()"
            >
              Retour à la page de connexion
            </b-link>
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'

import fr from 'vee-validate/dist/locale/fr.json'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

localize('fr', fr)

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      required,
      email,
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    localStorage.removeItem('internationalisation')
    // this.$store.dispatch('localeTraduction/fetchTraductions')
  },
  methods: {
    goLogin() {
      this.$router.push('login')
    },
    checkLogin() {
      const that = this
      this.loading = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          that.$store.dispatch('auth/sendRenewDemand', { email: this.username })
            .then(() => {
              that.loading = false
              that.$swal({
                title: 'Demande prise en compte',
                text: 'Vous allez recevoir un e-mail contenant un lien de changement de mot de passe',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$router.push({ name: 'auth-login' })
            })
            .catch(() => {
              that.$swal({
                width: 500,
                title: 'Changement non effectué',
                text: "Une erreur technique s'est produite. Veuillez contacter le support SQE Services",
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              that.loading = false
            })
        } else {
          that.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
